<template>
  <b-overlay
    variant="white"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
    :show="isLoading"
  >
    <validation-observer
      ref="resetPasswordFormRef"
      #default="{invalid}"
    >
      <b-modal
        id="resetPasswordModal"
        centered
        title="Đặt lại mật khẩu"
        @shown="onShown"
        @hide="onHide"
      >
        <b-form>
          <b-form-group label-for="password">
            <template v-slot:label>
              Mật khẩu <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Mật khẩu"
              rules="required|min:8|password"
            >
              <b-form-input
                id="password"
                v-model="account.password"
                name="password"
                placeholder="Nhập mật khẩu"
                :state="getElementState(errors)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
        <template #modal-footer>
          <div class="w-100 d-flex justify-content-end">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              :disabled="invalid"
              @click="onResetPassword()"
            >
              <span class="text-right">
                <feather-icon icon="CheckIcon" /> Lưu lại
              </span>
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              @click="$bvModal.hide('resetPasswordModal')"
            >
              <span class="text-right">
                <feather-icon icon="XIcon" /> Hủy
              </span>
            </b-button>
          </div>
        </template>
      </b-modal>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BModal, BOverlay,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { min, required } from '@core/utils/validations/validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ResetPassword',
  components: {
    BFormGroup,
    BFormInput,
    BModal,
    BForm,
    BOverlay,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      account: {
        id: undefined,
        password: 'Dhhl@123',
        type: 0,
      },
      required,
      min,
    }
  },
  computed: {
    ...mapGetters({
      defaultPassword: 'employee/defaultPassword',
    }),
  },
  methods: {
    ...mapActions({
      resetPassword: 'account/resetPassword',
      getDefaultPassword: 'employee/getDefaultPassword',
    }),
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    async onShown() {
      this.account.id = this.id
      this.isLoading = true
      try {
        await this.getDefaultPassword('ACCOUNT_DEFAULT_PASSWORD')
        this.account.password = this.defaultPassword
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.$refs
        .resetPasswordFormRef
        .reset()
      this.account = {
        id: undefined,
        password: '',
        type: 0,
      }
    },
    async onResetPassword() {
      const valid = this.$refs
        .resetPasswordFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.resetPassword(this.account)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$bvModal.hide('resetPasswordModal')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },
  },
}
</script>
