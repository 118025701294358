<template>
  <b-modal
    id="assignRoleModal"
    body-class="position-static"
    centered
    size="lg"
    title="Phân vai trò cho tài khoản"
    @shown="onShown"
    @hide="onHide"
  >
    <vue-good-table
      mode="remote"
      :select-options="selectOptions"
      :columns="columns"
      :rows="rows"
      :pagination-options="paginationOptions"
      :total-rows="totalRows"
      :line-numbers="true"
      max-height="500px"
      @on-selected-rows-change="selectionChanged"
      @on-page-change="onPageChange"
      @on-column-filter="onColumnFilter"
      @on-per-page-change="onPerPageChange"
    >
      <div
        slot="emptystate"
        style="text-align: center; font-weight: bold"
      >
        Không có bản ghi nào !
      </div>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span>{{ props.formattedRow[props.column.field] }}</span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Hiển thị 1 đến </span>
            <b-form-select
              v-model="filter.itemsPerPage"
              :options="itemsPerPageOptions"
              class="mx-1"
              @input="(value) => props.perPageChanged({ currentPerPage: value })"
            />
            <span class="text-nowrap">của {{ roles.length }} bản ghi</span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="totalRows"
              :per-page="filter.itemsPerPage"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            />
          </div>
        </div>
      </template>
    </vue-good-table>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end">
        <b-button
          v-if="isSystemAdmin() || creatable"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          class="mr-1"
          @click="onSave('hide')"
        >
          <span class="text-right">
            <feather-icon icon="CheckIcon" /> Lưu lại
          </span>
        </b-button>

        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="outline-secondary"
          @click="$bvModal.hide('assignRoleModal')"
        >
          <span class="text-right">
            <feather-icon icon="XIcon" /> Hủy
          </span>
        </b-button>
      </div>
    </template>
    <b-overlay
      no-wrap
      variant="white"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
      :show="isLoading"
    />
  </b-modal>
</template>

<script>
import {
  BButton, BFormSelect, BModal, BOverlay, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Flag } from '@/const/flag'
import { PermissionCode, ResourceCode } from '@/const/code'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'

export default {
  name: 'AssignRole',
  directives: {
    Ripple,
  },
  components: {
    BModal,
    BOverlay,
    BButton,
    VueGoodTable,
    BFormSelect,
    BPagination,
  },
  props: {
    id: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 50,
        code: '',
        status: Flag.ACTIVE,
        name: '',
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      selectOptions: {
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      },
      columns: [
        {
          label: 'Mã vai trò',
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          width: '35%',
          thClass: 'text-center',
        },
        {
          label: 'Tên vai trò',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: false,
          width: '45%',
          thClass: 'text-center',
        },
        {
          label: 'Thứ tự',
          field: 'orderNo',
          sortable: false,
          width: '20%',
          thClass: 'text-center',
          tdClass: 'text-right',
        },
      ],
      rows: [],
      originSelectedRows: [],
      rolesDeleted: [],
      rolesAdded: [],
    }
  },
  computed: {
    ...mapGetters({
      accountRoles: 'account/accountRoles',
      totalRows: 'role/totalRows',
      roles: 'role/roles',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.ACCOUNT_ROLE)
    },
  },
  methods: {
    isSystemAdmin,
    ...mapActions({
      getAccountRoles: 'account/getAccountRoles',
      getRoles: 'role/getRoles',
      saveAccountRoles: 'account/saveAccountRoles',
    }),
    async onShown() {
      await this.getData()
      this.rows = this.roles.map(role => {
        if (this.accountRoles.includes(role.id)) {
          this.originSelectedRows.push(role)
          return { ...role, vgtSelected: true }
        }
        return { ...role }
      })
    },
    async getData() {
      this.isLoading = true
      try {
        await Promise.all([
          this.getRoles(this.filter),
          this.getAccountRoles(this.id),
        ])
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onHide() {
      this.filter = {
        currentPage: 1,
        itemsPerPage: 50,
        code: '',
        status: Flag.ACTIVE,
        name: '',
      }
      this.rolesDeleted = []
      this.rolesAdded = []
      this.originSelectedRows = []
    },
    async onSave() {
      this.isLoading = true
      try {
        const response = await this.saveAccountRoles({
          id: this.id,
          rolesAdded: this.rolesAdded,
          rolesDeleted: this.rolesDeleted,
        })
        if (response) {
          const { isSuccessful, message } = response
          if (isSuccessful) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$emit('succeed')
            this.$bvModal.hide('assignRoleModal')
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
        }
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.onShown()
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: 1, itemsPerPage: param.currentPerPage })
      await this.onShown()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      await this.onShown()
    },
    selectionChanged(data) {
      this.rolesDeleted = this.originSelectedRows
        .filter(role => !data.selectedRows.find(selected => selected.id === role.id))
        .map(role => ({ accountId: this.id, roleId: role.id }))
      this.rolesAdded = data.selectedRows
        .filter(selected => !this.originSelectedRows.find(role => role.id === selected.id))
        .map(role => ({ accountId: this.id, roleId: role.id }))
    },
  },
}
</script>
